export {PublicAccessCodeComponent} from './PublicAccessCodeComponent';
export type {PublicAccessCodeComponentDefinition} from './PublicAccessCodeComponent';
export {
  ComponentDefinition,
  PublicAccessCodeInstructionSchema,
} from './PublicAccessCodeDefinition';
export type {SchemaType} from './PublicAccessCodeDefinition';
export {PublicAccessCodePreviewComponent} from './PublicAccessCodePreviewComponent';

import {Registry} from '@backstage-components/base';
import {PublicAccessCodeModule} from './PublicAccessCodeModule';
import {ComponentDefinition} from './PublicAccessCodeDefinition';
import {
  PublicAccessCodeSuccessEvent,
  PublicAccessCodeSuccessEventName,
} from './PublicAccessCodeMachine';
Registry.register(ComponentDefinition, PublicAccessCodeModule);

declare global {
  // Extends `#addEventListener` and `#removeEventListener` type definition to
  // include an overload for the `PublicAccessCodeSuccessEventName` custom event
  interface HTMLElement {
    addEventListener(
      type: typeof PublicAccessCodeSuccessEventName,
      listener: (this: HTMLElement, ev: PublicAccessCodeSuccessEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener(
      type: typeof PublicAccessCodeSuccessEventName,
      listener: (this: HTMLElement, ev: PublicAccessCodeSuccessEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
