import type {BroadcastFn} from '@backstage-components/base';
import {assertEvent, assign, setup} from 'xstate';
import {
  type InstructionSchema,
  PublicAccessCodeInstructionSchema,
} from './PublicAccessCodeDefinition';

export const PublicAccessCodeMachine = setup({
  types: {} as {
    context: Context;
    events: InstructionSchema;
    input: Pick<Context, 'broadcast' | 'showId'>;
  },
  actions: {
    broadcastFailure: ({context, event}) => {
      assertEvent(event, 'PublicAccessCode:failure');
      context.broadcast({
        type: 'PublicAccessCode:on-failure',
        meta: {
          showId: context.showId,
          reason: event.meta.reason,
        },
      });
    },
    broadcastSuccess: ({context, event}) => {
      assertEvent(event, 'PublicAccessCode:success');
      if (typeof window === 'object') {
        const e: PublicAccessCodeSuccessEvent = new CustomEvent(
          PublicAccessCodeSuccessEventName,
          {
            detail: {
              attendee: event.meta.attendee,
              passCode: context.passCode,
              showId: context.showId,
            },
          }
        );
        document.body.dispatchEvent(e);
      }
    },
    resetContext: assign(({context, event}): BaseContext => {
      assertEvent(event, 'PublicAccessCode:reset');
      return {broadcast: context.broadcast, showId: context.showId};
    }),
    stashPasscode: assign(({context, event}) => {
      assertEvent(event, 'PublicAccessCode:verify');
      return {
        ...context,
        passCode: event.meta.passCode,
      };
    }),
    updateAttendee: assign(({context, event}): SuccessContext => {
      assertEvent(event, 'PublicAccessCode:success');
      const attendee = event.meta.attendee;
      return {
        broadcast: context.broadcast,
        showId: context.showId,
        attendee,
        passCode: context.passCode,
      };
    }),
    updateReason: assign(({context, event}): FailureContext => {
      assertEvent(event, 'PublicAccessCode:failure');
      return {
        broadcast: context.broadcast,
        showId: context.showId,
        reason: event.meta.reason,
      };
    }),
  },
}).createMachine({
  id: 'PublicAccessCode',
  initial: 'idle',
  context: ({input}) => ({broadcast: input.broadcast, showId: input.showId}),
  states: {
    idle: {on: {'PublicAccessCode:verify': {target: 'pending'}}},
    pending: {
      entry: ['stashPasscode'],
      on: {
        'PublicAccessCode:success': {
          target: 'success',
          actions: ['updateAttendee'],
        },
        'PublicAccessCode:failure': {
          target: 'failure',
          actions: ['updateReason'],
        },
      },
    },
    success: {
      entry: ['broadcastSuccess'],
      on: {
        'PublicAccessCode:verify': {target: 'pending'},
      },
    },
    failure: {
      entry: ['broadcastFailure'],
      on: {
        'PublicAccessCode:reset': {actions: ['resetContext'], target: 'idle'},
        'PublicAccessCode:verify': {target: 'pending'},
      },
    },
  },
});

interface BaseContext {
  broadcast: BroadcastFn<typeof PublicAccessCodeInstructionSchema>;
  showId: string;
  attendee?: Attendee;
  passCode?: string;
}

interface FailureContext extends BaseContext {
  reason?: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
}

type Context = BaseContext | FailureContext | SuccessContext;

interface Attendee {
  id: string;
  email: string | null;
  name: string;
  chatTokens: ChatToken[];
  tags: string[];
}

interface ChatToken {
  token: string;
}

export const PublicAccessCodeSuccessEventName = 'PublicAccessCode:success';

export type PublicAccessCodeSuccessEvent = CustomEvent<
  Pick<SuccessContext, 'attendee' | 'passCode' | 'showId'>
>;
